.title {
  font-family: Roboto;
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  padding-left: 15px;
}

.title2 {
  font-family: Roboto;
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  color: var(--lightText-color);
}

.subtitle {
  height: auto;
  font-weight: 500;
  font-size: 25px;
  color: var(--mainText-color);
  text-align: start;
}
  
.titleContent {
  color: var(--mainText-color);
  height: auto;
  width: 100%;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: start;

}
  
.imageContainer {
  position: relative; /* Make sure the container can have child elements positioned */
  background-image: url('../../Images/Graduation.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.imageContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 0; /* Make sure the overlay is below the content */
}

.titleOverlay {
  background-color: var(--primary-color);
  opacity: 0.9;
  padding: 20px;
  width: 100%;
  max-height: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.bodyContainer {
  background: var(--primary-color);
  width: 100%;
}

.content1 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--mainText-color);
  padding-left: 15px;
}

.content2 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--lightText-color);
  padding-right: 15px;
}

.midSection {
  justify-content: space-between;
  display: flex;
  align-items: center;
  background-color: var(--grayBg-color);
  min-height: 70vh;
}

.sellingPoint {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  color: var(--lightText-color);
  padding-left: 15px;
}

@media (max-width: 768px) {
  .sellingPoint {
    padding-top: 30px;
  }
} 

@media (max-width: 500px) {
  .titleContent {
    color: var(--mainText-color);
    height: auto;
    width: 100%;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    text-align: start;
    padding: 0px !important;
  }

  .titleOverlay {
    padding: 0px;
  }

  .imageContainer {
    height: 75vh;
  }

  .sellingPoint {
    display: none;
  }

  .content2 {
    padding-left: 15px;
    padding-right: 0px;
  }

  .title2 {
    padding-left: 15px;
    padding-right: 0px;
  }
}

@media (max-width: 400px) {
  .titleContent {
    color: var(--mainText-color);
    height: auto;
    width: 100%;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    line-height: 25px;
    text-align: start;
    padding: 0px !important;
  
  }
}