:root {
  --primary-color: #F0F0F0;
  --secondary-color: #E8E8E8;
  --accent-color: #800000;
  --mainText-color: #181818;
  --lightText-color: #FFFFFF;
  --navlink-color: #000000;
  --text-color:#141414;
  --subdued-color: #515151;
  --grayBg-color: #3E3D3C;



}

body {
  font-family: Roboto;
}
