.body {
  background-color: var(--primary-color);
}

.custContainer {
  min-height: auto;
}

.title {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  padding-top: 20px;
  color: var(--mainText-color);
}

.subtitle {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  text-align: left;

}

.mainTitle {
  font-family: Roboto;
  font-size: 62px;
  font-weight: 600;
  line-height: 100px;
  padding-top: 20px;
  color: var(--mainText-color);
  width: 100%;
  text-align: center;
}

.workTogether {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
}

.workTogether > div {
  margin: 100px 0;
}

.bodyText {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding-top: 20px;
  color: var(--mainText-color);
  width: 80%;
  text-align: left;
}

.bottomContainer {
  align-items: start;
  margin-top: 110px !important;
}

.email {
  width: 60%;
}
  
.imageContainer {
  position: relative; /* Make sure the container can have child elements positioned */
  background-image: url('../../Images/Parked_Trucks.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure the content within the container is on top of the overlay */
}

.imageContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.664);
  z-index: 0; /* Make sure the overlay is below the content */
}

.titleOverlay {
  background-color: var(--primary-color);
  border-radius: 10px;
  padding-left: 50px;
  padding-right: 50px;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactMethodsContainer {
  position: absolute;
  top: 60%;
  z-index: 3;
  width: 100%;
}

.contactMethods {
  background-color: var(--secondary-color);
  opacity: 0.95;
  border: 1px solid var(--mainText-color);
  border-radius: 10px;
  padding: 15px;
  max-width: 400px;
  height: 120px;
}

.emailContainer {
  width: 90%;
  margin: auto;
}

@media (max-width: 768px) {
  .mainTitle {
    font-size: 58px;
  }
  
  .bottomContainer {
    flex-direction: column;
  }

  .workTogether {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  
  .workTogether > div {
    margin-top: 20px;
  }

  .title, .bodyText {
    text-align: center;
    width: 100%;
  }

  .email {
    width: 100%;
  }

  .contactMethodsContainer {
    top: 60%;
  }
}

@media (max-width: 576px) {
  .workTogether {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contactMethodsContainer {
    top: 33%;
  }

  .bottomContainer {
    margin-top: 0px !important;
  }

  .titleOverlay {
    display: none;
  }

}
