.customFormContainer {
  border-radius: 10px;
  background-color: var(--secondary-color);
  border: 1px solid var(--navlink-color);
  max-width: 100% !important;
}

.formTitle {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 28px;
  color: var(--accent-color);
  display: flex;
  justify-content: center;
  align-self: center;
}

.customFormControl {
  border: 1px solid var(--navlink-color);
}

.customFormControl:focus {
  border: 1px solid var(--navlink-color);
  outline: none;
  box-shadow: none;
}

.customBtn {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding: 11px 27px !important;
  border-radius: 24px !important;
  width: 70% !important;
  background-color: var(--accent-color) !important;
  border: 1px solid var(--accent-color) !important;
  color: var(--lightText-color) !important;
  transition: all .25s;
}

.customBtn:hover {
  background-color: var(--accent-color) !important;
  border: 1px solid var(--accent-color) !important;
  transform: scale(1.08);
}

.finePrint {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--subdued-color);
}

@media (max-width: 768px) {
  .customFormContainer {
    max-width: 95% !important;
  }
}