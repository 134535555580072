.subtitle {
    height: auto;
    font-weight: 500;
    font-size: 25px;
    color: var(--mainText-color);
    text-align: start;
  }
    
  .titleContent {
    color: var(--mainText-color);
    height: auto;
    width: 100%;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: start;
  
  }
    
  .imageContainer {
    position: relative; /* Make sure the container can have child elements positioned */
    background-image: url('../../Images/Teaching.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Ensure the content within the container is on top of the overlay */
  }
  
  .imageContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* This adds the dark overlay */
    z-index: 0; /* Make sure the overlay is below the content */
  }
  
  .titleOverlay {
    background-color: var(--primary-color);
    opacity: 0.9;
    padding: 20px;
    width: 100%;
    max-height: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .background {
    background-color: var(--primary-color);
    width: 100%;
  } 

  .title2 {
    font-family: Roboto;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    line-height: 45px;
    text-align: left;
    color: var(--lightText-color);
    padding-left: 30px;
  }

  .content2 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: var(--lightText-color);
    width: 100%;
    padding: 15px;
  }

  #verticalDivider {
    color: var(--primary-color);
    padding: 0px;
  }

  .midSection {
    background-color: var(--grayBg-color);
    min-height: 50vh;
    width: 100%
  }

  .sellingPointContainer {
    display: flex;
    justify-content: end;
    align-items: end;
  }
  
  .sellingPoint {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
    color: var(--lightText-color);
    width: 85%;
    padding-right: 15px;
  }

  .title3 {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
    color: var(--mainText-color);
    width: 85%;
    padding-left: 15px;
  }


  @media (max-width: 768px) {
    .sellingPointContainer {
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .sellingPoint {
      text-align: center;
      padding-right: 0px;
    }

    .explanationContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .content2 {
      padding-right: 10px;
      padding-left: 10px;
      text-align: center;
    }

    .title2 {
      padding-left: 0px;
    }

    .title3 {
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      text-align: center;
    }
  }

  @media (max-width: 504px) {
    .title2 {
      text-align: center;
    }
  }

  @media (max-width: 336px) {
    .titleContent {
      font-size: 15px;
    }

    .sellingPoint {
      font-size: 26px;
    }

    .title3 {
      font-size: 26px;
    }
  }