.imageContainer {
  position: relative; /* Make sure the container can have child elements positioned */
  background-image: url('../../Images/Footer_Semi.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure the content within the container is on top of the overlay */
}

/* Add the dark overlay using ::before */
.imageContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* This adds the dark overlay */
  z-index: 0; /* Make sure the overlay is below the content */
}


.logoContainer {
  padding: 20px;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}