.customNavbar {
  background: var(--primary-color);
  width: 100% !important;
}

#custContainer {
  margin-left: 10px;
  margin-right: 10px;
  width: 100% !important;
}

.navLink {
  color: var(--mainText-color) !important;
  font-weight: 500 !important;
  border-radius: 21px;
  padding: 11px 27px !important;
}

.navLink:hover {
  background-color: var(--accent-color) !important;
  color: var(--secondary-color) !important;
  border-radius: 21px;

}

.navLinkActive {
  background-color: var(--accent-color) !important;
  color: var(--secondary-color) !important;
  border-radius: 21px !important;
}

@media (min-width: 576px) {
  #custContainer {
      max-width: 100%;
  }
}

@media (min-width: 768px) {
  #custContainer {
      max-width: 100%;
  }
}

@media (min-width: 992px) {
  #custContainer {
      max-width: 972px; 
  }
}

@media (min-width: 1200px) {
  #custContainer {
      max-width: 1180px;
  }
}

@media (min-width: 1400px) {
  #custContainer {
      max-width: 100%; 
  }
}