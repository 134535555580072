.subtitle {
  height: auto;
  font-weight: 500;
  font-size: 25px;
  color: var(--mainText-color);
  text-align: start;
}
  
.titleContent {
  color: var(--mainText-color);
  height: auto;
  width: 100%;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: start;

}
  
.imageContainer {
  position: relative; /* Make sure the container can have child elements positioned */
  background-image: url('../../Images/Learning.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure the content within the container is on top of the overlay */
}

.imageContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* This adds the dark overlay */
  z-index: 0; /* Make sure the overlay is below the content */
}

.titleOverlay {
  background-color: var(--primary-color);
  opacity: 0.9;
  padding: 20px;
  width: 100%;
  max-height: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.bodyContainer {
  background-color: var(--primary-color);
  width: 100%;
}

.courseContainer {
  transition: all .25s;
}

.courseContainer:hover {
  transform: scale(1.08);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.courseLeft {
  display: flex;
  justify-content: start;
}

.courseRight {
  display: flex;
  justify-content: end;
}

.bodyTitle {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 28px;
  color: var(--accent-color);
  display: flex;
  justify-content: center;
  align-self: center;
}

.courseTitle {
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  font-style: italic;
  line-height: 25px;
  background-color: var(--primary-color);
  color: var(--text-color);
}

.customListGroup {
  background-color: var(--primary-color);
  min-width: 250px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}

.customListItem {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;

}

.courseLink {
  font-family: Roboto;
  color: var(--accent-color);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  transition: all .25s;
}

.courseLink:hover {
  transform: scale(1.08);
  cursor: pointer;
}

@media (max-width: 500px) {
  .courseContainer {
    flex-direction: column;
  }
  .courseLeft, .courseRight {
    justify-content: center;
  }

  .image {
    display: none;
  }
}

@media (max-width: 396px) {
  .titleContent {
    color: var(--mainText-color);
    height: auto;
    width: 100%;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    text-align: start;
    padding: 0px !important;
  }

  .titleOverlay {
    padding: 0px;
  }

  .subtitle {
    padding-bottom: 0px !important;
  }
}