.title {
  height: auto;
  font-weight: 500;
  font-size: 35px;
  color: var(--accent-color);
  text-align: center;
}

.subtitle {
  height: auto;
  font-weight: 500;
  font-size: 25px;
  color: var(--mainText-color);
  text-align: center;
}

.titleContent {
  color: var(--mainText-color);
  height: auto;
  width: 100%;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;

}

.imageContainer {
  position: relative; /* Make sure the container can have child elements positioned */
  background-image: url('../../Images/Semi_Road.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure the content within the container is on top of the overlay */
}

.imageContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* This adds the dark overlay */
  z-index: 0; /* Make sure the overlay is below the content */
}

.titleOverlay {
  background-color: var(--primary-color);
  opacity: 0.9;
  padding: 20px;
  width: 100%;
  max-height: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bodyContainer {
  background-color: var(--primary-color);
}

.bodyTitle {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 28px;
  color: var(--accent-color);
  display: flex;
  justify-content: center;
  align-self: center;
}

.courseTitle {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  line-height: 25px;
  color: var(--text-color);
}

.customListGroup {
  min-width: 250px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}

.customListItem {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
}

.courseLink {
  font-family: Roboto;
  color: var(--accent-color);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  transition: all .25s;
}

.courseLink:hover {
  transform: scale(1.08);
  cursor: pointer;
}

.emailOverlap {
  position: absolute;
  top: 75%;
  z-index: 3;
}

.emailContainer {
  width: 80%;
  margin: auto;
}

@media (max-width: 768px) {
  .emailOverlap {
    position: relative;
    top: 0;
    z-index: 0;
  }
}

@media (max-width: 500px) {
  #custContainer {
      max-width: 100%; 
  }
}

@media (max-width: 390px) {
  .emailContainer {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 325px) {
  .titleContent {
    font-size: 15px;
  }
}